require('./bootstrap');


// require('livewire-vue');

// import Vue from 'vue';
// import 'livewire-vue';

// window.Vue = Vue // 'require('vue');' won't work!


// Vue.component('query-builder-keywords', require('./components/QueryBuilderKeywords.vue').default);

// const app = new Vue({
//     el: '#main-app',
// });


import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import ToastComponent from '../../vendor/usernotnull/tall-toasts/dist/js/tall-toasts'


import Tooltip from "@ryangjchandler/alpine-tooltip";

window.tippy = require('tippy.js').default;;


// require('./tooltip')

tippy('[data-tippy-content]', {
	allowHTML: true,
	placement: 'auto',
	theme: 'light'
});

Alpine.plugin(collapse)
Alpine.plugin(Tooltip);


Alpine.data('ToastComponent', ToastComponent)

window.Alpine = Alpine;

Alpine.start()

